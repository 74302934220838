import React, {useState} from "react";
import {FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput,} from "@material-ui/core";
import "date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DatePicker from "react-date-picker";
import "./styles.css";
import moment from "moment";

interface IProps {
    label?: string;
    locale: string;
    name?: string;
    readOnly?: boolean;
    required?: boolean;
    value?: string;
    error?: string;
    onChange: (value?: string) => void;
}

const DatePickerCustom: React.FC<IProps> = React.memo(
    ({
         label,
         locale,
         name,
         readOnly,
         required,
         value,
         error,
         onChange,
     }) => {
        const [open, setOpen] = useState(false);
        const [date, setDate] = useState(value ? moment(value).toDate() : undefined);
        const [displayValue, setDisplayValue] = useState(value ? moment(value).format("L") : "");

        const calendarDateUpdated = (date: Date | Date[]): void => {
            const validDate: Date = Array.isArray(date) ? date[0] : date;
            const value = validDate ? moment(validDate) : undefined;
            setDate(value ? value.toDate() : undefined);
            setDisplayValue(value ? value.format("L") : "");
            onChange(value ? value.format('YYYY-MM-DD[T00:00:00]') : undefined);
        }

        const inputBlur = () => {
            const pastedText = displayValue.trim();
            let date = moment(pastedText, 'MM/DD/YYYY');
            if (!date.isValid()) {
                date = moment(pastedText, 'DD.MM.YYYY');
            }
            if (!date.isValid()) {
                date = moment(pastedText, 'YYYY-MM-DD');
            }
            if (!date.isValid()) {
                date = moment(pastedText, 'DD-MM-YYYY');
            }
            if (!date.isValid()) {
                date = moment(pastedText);
            }
            if (date.isValid()) {
                setDate(date.toDate());
                setDisplayValue(date.format("L"));
                onChange(date.format('YYYY-MM-DD[T00:00:00]'));
            } else {
                setDate(undefined);
                setDisplayValue('');
                onChange(undefined);
            }
        }

        return (
            <>
                <InputLabel htmlFor={name} color="secondary">
                    {label}
                </InputLabel>
                <FormControl
                    error={!!error}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                >
                    <OutlinedInput
                        autoComplete="off"
                        className={readOnly ? "read-only" : ""}
                        fullWidth
                        id={name}
                        name={name}
                        required={required}
                        value={displayValue}
                        placeholder="MM/DD/YYYY"
                        onChange={(event) => setDisplayValue(event.target.value)}
                        onBlur={inputBlur}
                        startAdornment={
                            <DatePicker
                                isOpen={open}
                                locale={locale}
                                maxDate={new Date()}
                                onChange={calendarDateUpdated}
                                value={date}
                                onCalendarClose={() => setOpen(false)}
                            />
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="date picker" size="small" onClick={() => !readOnly && setOpen(true)}>
                                    <DateRangeIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText>{error || ""}</FormHelperText>
                </FormControl>
            </>
        );
    }
);

export default DatePickerCustom;
