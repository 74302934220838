import React from "react";
import { Typography, Link as LinkButton } from "@material-ui/core";

interface IProps {
	text: string;
	secondText?: string;
	link?: string;
	center?: boolean;
	linkText?: string;
	className?: any;
}

const Info: React.FC<IProps> = ({
	text,
	link,
	center,
	linkText,
	secondText,
	className,
}) => {
	return (
		<Typography variant="body1" align={center ? "center" : "left"}>
			{text}{" "}
			{link && (
				<LinkButton
					target="_blank"
					href={link.indexOf("@") !== -1 ? `mailto:${link}` : link}
					className={className}
				>
					{link.indexOf("@") !== -1 ? link : linkText}
				</LinkButton>
			)}{" "}
			{secondText}
		</Typography>
	);
};

export default Info;
