import * as React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { Box, CircularProgress } from "@material-ui/core";

interface ILoadingBtn {
  fetching: boolean;
  label: string;
  type?: string;
  disabled?: boolean;
  onClick?: (event: any) => void;
  fullWidth?: boolean;
  className?: string;
}

const LoadingBtn: React.FC<ButtonProps & ILoadingBtn> = ({
  fetching,
  label,
  fullWidth,
  className,
  disabled,
  ...rest
}) => {
  return (
    <Button
      fullWidth={fullWidth}
      className={className}
      disabled={disabled || fetching}
      {...rest}
    >
      {label}
      {fetching ? (
        <Box ml={1}>
          <CircularProgress size={16} style={{ verticalAlign: "middle" }} />
        </Box>
      ) : null}
    </Button>
  );
};

export default LoadingBtn;
