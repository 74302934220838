import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import {
	Grid,
	Box,
	Container,
	Divider,
	Button,
	Checkbox,
	Typography,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";

import DescriptionAndIntentToCollect from "./DescriptionAndIntentToCollect/DescriptionAndIntentToCollect";
import OwnerInformation from "./OwnerInformation/OwnerInformation";
import FlyoutMessage from "./FlyoutMessage";
import { validate as validateBusiness } from "./validateBusiness";
import { useStyles } from "./styles";
import Footer from "../components/Footer";
import Title from "../components/Title";
import Info from "../components/Info";
import Navbar from "../components/Navbar";
import InputCustom from "../components/InputCustom";
import SelectCustom from "../components/SelectCustom";
import GlobalContext from "../context/global-context";
import countries from "../countries.json";
import { getMerchant, setMerchant } from "../services";
import { IMerchant } from "../model";

const platforms = ["MB", "MM", "Efit", "EM"];

const Main: React.FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const params = useParams<{ id: string }>();
	const {
		auth,
		isMessageCheckOpen,
		isMessageEmptyOpen,
		openMessageCheckout,
		openMessageEmpty,
	} = useContext(GlobalContext);

	const [merchant, setMechant] = useState<IMerchant>();
	const [responseBisiness, setResponceBisiness] = useState<any>({ owners: [] });
	const [checked, setChecked] = useState<boolean>(false);
	const [isSumbit, setIsSubmit] = useState<boolean>(false);
	const [choosedCountry, setChoosedCountry] = useState<string>("");
	const [legalBusinessName, setLegalBusinessName] = useState<string>("");
	const [doingBusinessName, setDoingBusinessName] = useState<string>("");
	const [taxIdNumber, setTaxIdNumber] = useState<string>("");
	const [businessPhone, setBusinessPhone] = useState<string>("");
	const [businessEmail, setBusinessEmail] = useState<string>("");
	const [businessWebsite, setBusinessWebsite] = useState<string>("");
	const [countryBusiness, setCountryBusiness] = useState<string>("");
	const [addressBusiness, setAddressBusiness] = useState<string>("");
	const [address2Business, setAddress2Business] = useState<string>("");
	const [cityBusiness, setCityBusiness] = useState<string>("");
	const [provinceBusiness, setProvinceBusiness] = useState<string>("");
	const [postalCodeBusiness, setPostalCodeBusiness] = useState<string>("");
	const [externalId, setExternalId] = useState<string>("");
	const [errorsBusiness, setErrorsBusiness] = useState<any>({});
	const myRef = useRef<HTMLHeadingElement>(null);
	const [isMessage, setIsMessage] = useState(false);
	const [allOwnersExist, setAllOwnersExist] = useState(false);
	const [ownerValidateDone, setownerValidateDone] = useState(false);
	const [isDataLoadingDone, setIsDataLoadingDone] = useState(false);

	const optionCountry = useMemo(() => {
		return countries.map((item) => ({
			label: item.name,
			value: item.code,
		}));
	}, []);

	const optionProvince = useMemo(() => {
		const country = countries.find((item) => item.code === choosedCountry);

		if (country) {
			return country.states.map((item) => ({
				label: item.name,
				value: item.code,
			}));
		}

		return [];
	}, [choosedCountry]);

	const fetchData = async () => {
		const data = await getMerchant(params.id, auth);
		setMechant(data);
	};

	useEffect(() => {
		if (merchant) {
			document.title =
				merchant.platformId === 3
					? "eFit - Verification Form"
					: "MSI - Verification Form";
		}

		const favicon = document.querySelector("#favicon");
		if (favicon && merchant && merchant.platformId !== 3) {
			favicon.setAttribute("href", "/msi-logo-fullcolor-cube.png");
		}

		if (favicon && merchant && merchant.platformId === 3) {
			favicon.setAttribute("href", "/efit-logo-icon.png");
		}
	}, [merchant]);

	useEffect(() => {
		if (auth) {
			fetchData();
		} else {
			history.goBack();
		}
	}, [auth]);

	useEffect(() => {
		if (merchant) {
			setLegalBusinessName(merchant.legalBusinessName);
			setDoingBusinessName(merchant.doingBusinessName);
			setTaxIdNumber(merchant.taxId.trim());
			setBusinessPhone(merchant.phone.trim());
			setBusinessEmail(merchant.email.trim());
			setBusinessWebsite(merchant.website.trim());
			setCountryBusiness(merchant.country.trim());
			setAddressBusiness(merchant.address.trim());

			setProvinceBusiness(merchant.state.trim());
			setPostalCodeBusiness(merchant.zipCode.trim());
			setExternalId(merchant.externalId);

			if (merchant.country) {
				setChoosedCountry(merchant.country.trim());
			}

			if (merchant.city) {
				setCityBusiness(merchant.city.trim());
			}

			if (merchant.address2) {
				setAddress2Business(merchant.address2.trim());
			}

			setErrorsBusiness({});

			setIsDataLoadingDone(true);
		}
	}, [merchant]);

	const handleOwner = (owner: any, index: number) => {
		const withOwner = { ...responseBisiness };
		withOwner.owners[index] = owner;
		setResponceBisiness(withOwner);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setErrorsBusiness({});
		setIsSubmit(true);
		setIsMessage(false);
		const isValid = validateBusiness({
			legalBusinessName,
			doingBusinessName,
			taxIdNumber,
			businessPhone,
			businessEmail,
			businessWebsite,
			countryBusiness,
			addressBusiness,
			address2Business,
			cityBusiness,
			provinceBusiness,
			postalCodeBusiness,
			checked,
			externalId,
		});

		setErrorsBusiness(isValid);

		if (Object.keys(isValid).length > 0 && isValid.checked && !checked) {
			openMessageCheckout(true);
			return;
		}

		if (Object.keys(isValid).length > 0 && myRef.current && !isValid.checked) {
			openMessageEmpty(true);
			setIsMessage(true);
			return;
		}

		setAllOwnersExist(true);

		if (merchant && auth && Object.keys(isValid).length === 0) {
			setResponceBisiness({
				...responseBisiness,
				externalId,
				legalBusinessName,
				doingBusinessName,
				taxId: taxIdNumber,
				phone: businessPhone,
				address: addressBusiness,
				city: cityBusiness,
				zipCode: postalCodeBusiness,
				email: businessEmail,
				website: businessWebsite,
				country: countryBusiness,
				state: provinceBusiness,
				address2: address2Business ? address2Business : null,
			});
		}
	};

	const setMerchantData = async () => {
		if (merchant) {
			try {
				await setMerchant(
					{
						...responseBisiness,
						externalId: merchant.externalId,
						masterBusinessId: merchant.masterBusinessId,
						platform: platforms[merchant.platformId - 1],
					},
					auth
				);
				let checkPassports = () => {
					let passportCount = 0;
					responseBisiness.owners.map((owner: any) => {
						if (owner.passportNo.length > 0) {
							passportCount = passportCount + 1;
						}
					});
					return passportCount;
				};
				let passportCount = checkPassports();
				console.log("check Passports", passportCount);
				if (merchant.platformId === 3) {
					history.push("/result/efit");
				}
				if (merchant.platformId !== 3 && !passportCount) {
					history.push("/result/msi");
				}
				if (merchant.platformId !== 3 && passportCount) {
					history.push("/result/msi/passport");
				}

				setMechant(undefined);
				setLegalBusinessName("");
				setDoingBusinessName("");
				setTaxIdNumber("");
				setBusinessPhone("");
				setBusinessEmail("");
				setBusinessWebsite("");
				setCountryBusiness("");
				setAddressBusiness("");

				setProvinceBusiness("");
				setPostalCodeBusiness("");
				setChoosedCountry("");
				setCityBusiness("");
				setAddress2Business("");
				setExternalId("");
				setIsDataLoadingDone(false);
			} catch (error) {
				console.log("error", error);
			}
		}
	};

	useEffect(() => {
		if (
			responseBisiness &&
			merchant &&
			responseBisiness.owners.length === merchant.owners.length &&
			responseBisiness.owners.filter((owner: any) => owner.fullName).length ===
				merchant.owners.length
		) {
			setMerchantData();
		}
	}, [responseBisiness, merchant]);

	useEffect(() => {
		if (!isMessageEmptyOpen && myRef.current && isMessage) {
			myRef.current.scrollIntoView();
			setIsMessage(false);
		}
	}, [isMessageEmptyOpen, isMessage]);

	useEffect(() => {
		if (allOwnersExist && ownerValidateDone) {
			setIsSubmit(false);
		}
	}, [allOwnersExist, ownerValidateDone]);
	return (
		<div style={{ padding: 20, overflow: "hidden" }}>
			{merchant && (
				<>
					<Navbar platform={merchant.platformId === 3 ? "efit" : "msi"} />

					<Container maxWidth="lg">
						<Grid container spacing={3} style={{ padding: 50 }}>
							<DescriptionAndIntentToCollect
								platform={merchant.platformId === 3 ? "efit" : "msi"}
							/>
							{merchant.platformId === 3 ? (
								<Grid item xs={12}>
									<Box display="flex" style={{ padding: "20px 0 30px" }}>
										<Info
											//text change
											text="If you have and questions or issues, please contact client services at 1-877-772-3348 or "
											link="contact@efitfinancial.com"
											className={classes.efitLink}
										/>
									</Box>
								</Grid>
							) : (
								<Grid item xs={12}>
									<Box display="flex" style={{ padding: "20px 0 30px" }}>
										<Info
											text="If you have and questions or issues, please contact client services at 1-888-277-4407 or "
											link="client@membersolutions.com"
										/>
									</Box>
								</Grid>
							)}
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<form
								onSubmit={handleSubmit}
								noValidate
								autoComplete="new-password"
							>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Title label="Business Information" variant="title" />
									</Grid>
									{isDataLoadingDone && (
										<Grid item xs={12} id="business" ref={myRef}>
											<Grid container spacing={1} justify="space-between">
												<Grid item xs={12} md={5}>
													<Grid container spacing={1}>
														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.externalId}
																label={
																	merchant.platformId === 3
																		? "Club ID *"
																		: "Client ID *"
																}
																name="externalId"
																disabled
																required
																value={externalId}
															/>
														</Grid>
														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.legalBusinessName}
																label="Legal Business Name *"
																name="legalBusinessName"
																title="Legal Business Name is the new-passwordicial name of the entity that owns a company. It is also the name you use on your government forms and business paperwork."
																required
																defaultValue={legalBusinessName}
																onBlur={(event) => {
																	setLegalBusinessName(
																		event.target.value.trim()
																	);
																	const isValid = validateBusiness({
																		legalBusinessName:
																			event.target.value.trim(),
																	});
																	setErrorsBusiness({
																		...errorsBusiness,
																		legalBusinessName:
																			isValid.legalBusinessName,
																	});
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.doingBusinessName}
																label="Doing Business as Name *"
																name="doingBusinessName"
																title={`DBA or "Doing Business as" name is a way of doing business under a particular name filed in a state or county. A DBA is an alias and is not an actual business entity.`}
																required
																defaultValue={doingBusinessName}
																onBlur={(event) => {
																	setDoingBusinessName(
																		event.target.value.trim()
																	);
																	const isValid = validateBusiness({
																		doingBusinessName:
																			event.target.value.trim(),
																	});
																	setErrorsBusiness({
																		...errorsBusiness,
																		doingBusinessName:
																			isValid.doingBusinessName,
																	});
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.taxIdNumber}
																label="Tax ID Number (TIN)/Business Number (BN) *"
																name="taxIdNumber"
																required
																autoComplete="new-password"
																defaultValue={taxIdNumber}
																onBlur={(event) => {
																	setTaxIdNumber(event.target.value.trim());

																	const isValid = validateBusiness({
																		taxIdNumber: event.target.value.trim(),
																	});

																	setErrorsBusiness({
																		...errorsBusiness,
																		taxIdNumber: isValid.taxIdNumber,
																	});
																}}
															/>
															<Typography
																variant="caption"
																style={{ color: "#DE350A" }}
															>
																CA clients: Provide 15 digits BN (ex.
																123456789RT0001)
															</Typography>
														</Grid>
														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.businessPhone}
																label="Business Phone *"
																name="businessPhone"
																autoComplete="new-password"
																type="tel"
																maxLength={15}
																required
																defaultValue={businessPhone}
																onBlur={(event) => {
																	setBusinessPhone(event.target.value.trim());

																	const isValid = validateBusiness({
																		businessPhone: event.target.value.trim(),
																	});

																	setErrorsBusiness({
																		...errorsBusiness,
																		businessPhone: isValid.businessPhone,
																	});
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.businessEmail}
																label="Business Email *"
																name="businessEmail"
																autoComplete="new-password"
																type="email"
																required
																defaultValue={businessEmail}
																onBlur={(event) => {
																	setBusinessEmail(event.target.value);

																	const isValid = validateBusiness({
																		businessEmail: event.target.value.trim(),
																	});

																	setErrorsBusiness({
																		...errorsBusiness,
																		businessEmail: isValid.businessEmail,
																	});
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.businessWebsite}
																label="Business Website or Social Media *"
																name="businessWebsite"
																autoComplete="new-password"
																required
																defaultValue={businessWebsite}
																onBlur={(event) => {
																	setBusinessWebsite(event.target.value);

																	const isValid = validateBusiness({
																		businessWebsite: event.target.value.trim(),
																	});

																	setErrorsBusiness({
																		...errorsBusiness,
																		businessWebsite: isValid.businessWebsite,
																	});
																}}
															/>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={12} md={5}>
													<Grid container spacing={1}>
														<Grid item xs={12}>
															<SelectCustom
																error={errorsBusiness.countryBusiness}
																label="Country *"
																name="country"
																options={optionCountry}
																value={countryBusiness}
																onChange={(event) => {
																	setChoosedCountry(event.target.value);
																	setCountryBusiness(event.target.value);
																	setProvinceBusiness("");
																}}
															/>
														</Grid>

														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.addressBusiness}
																label="Address *"
																name="address"
																autoComplete="new-password"
																required
																title="Please enter the address where your business is located. P.O. boxes are not allowed"
																defaultValue={addressBusiness}
																onBlur={(event) => {
																	setAddressBusiness(event.target.value.trim());

																	const isValid = validateBusiness({
																		addressBusiness: event.target.value.trim(),
																	});

																	setErrorsBusiness({
																		...errorsBusiness,
																		addressBusiness: isValid.addressBusiness,
																	});
																}}
															/>
														</Grid>

														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.address2Business}
																label="Address 2"
																name="address2"
																title="Please enter the address where your business is located. P.O. boxes are not allowed"
																autoComplete="new-password"
																defaultValue={address2Business}
																onBlur={(event) => {
																	setAddress2Business(
																		event.target.value.trim()
																	);

																	const isValid = validateBusiness({
																		address2Business: event.target.value.trim(),
																	});

																	setErrorsBusiness({
																		...errorsBusiness,
																		address2Business: isValid.address2Business,
																	});
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<InputCustom
																error={errorsBusiness.cityBusiness}
																label="City *"
																name="city"
																autoComplete="new-password"
																required
																defaultValue={cityBusiness}
																onBlur={(event) => {
																	setCityBusiness(event.target.value.trim());

																	const isValid = validateBusiness({
																		cityBusiness: event.target.value.trim(),
																	});

																	setErrorsBusiness({
																		...errorsBusiness,
																		cityBusiness: isValid.cityBusiness,
																	});
																}}
															/>
														</Grid>
														<Grid item xs={6}>
															<SelectCustom
																error={errorsBusiness.provinceBusiness}
																label="State/Province *"
																name="province"
																options={optionProvince}
																value={provinceBusiness}
																disabled={!choosedCountry}
																onChange={(event) => {
																	setProvinceBusiness(event.target.value);
																}}
															/>
														</Grid>
														<Grid item xs={6}>
															<InputCustom
																error={errorsBusiness.postalCodeBusiness}
																label="Zip/Postal Code *"
																name="postalCode"
																autoComplete="new-password"
																required
																defaultValue={postalCodeBusiness}
																onBlur={(event) => {
																	setPostalCodeBusiness(
																		event.target.value.trim()
																	);

																	const isValid = validateBusiness({
																		postalCodeBusiness:
																			event.target.value.trim(),
																	});

																	setErrorsBusiness({
																		...errorsBusiness,
																		postalCodeBusiness:
																			isValid.postalCodeBusiness,
																	});
																}}
															/>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									)}
									<Grid item xs={12}>
										<Divider />
									</Grid>

									<Grid item xs={12}>
										<Title label="Owner Information" variant="title" />
									</Grid>
									{isDataLoadingDone &&
										merchant.owners &&
										merchant.owners.map((owner, index) => {
											return (
												<React.Fragment key={index}>
													<OwnerInformation
														number={index}
														owner={owner}
														isSumbit={isSumbit}
														setResponce={handleOwner}
														allOwnersExist={allOwnersExist}
														setownerValidateDone={setownerValidateDone}
													/>
												</React.Fragment>
											);
										})}
									<Grid item xs={12}>
										<Box display="flex" style={{ padding: "20px 0" }}>
											<Box display="flex">
												<Checkbox
													color="default"
													size="small"
													checked={checked}
													onChange={() => setChecked(!checked)}
													style={{ marginRight: 20, minWidth: 50 }}
													disableRipple
												/>
											</Box>
											<Info
												text={
													merchant.platformId === 3
														? "I hereby consent to the processing of my personal data for the selected purposes, and agree that eFit Financial may collect, store, process and use the personal data I have provided."
														: "I hereby consent to the processing of my personal data for the selected purposes, and agree that Member Solutions may collect, store, process and use the personal data I have provided."
												}
											/>
										</Box>
									</Grid>

									<Grid item xs={12}>
										<Box display="flex" justifyContent="center">
											<Button
												fullWidth
												className={
													merchant.platformId === 3
														? classes.submitButton
														: classes.submitButtonMSI
												}
												type="submit"
												onClick={() => setAllOwnersExist(false)}
											>
												Submit
											</Button>
										</Box>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</Container>
					<Footer />
					<FlyoutMessage
						message="Some of the required fields are empty or incorrect. Please check the fields mark red and resubmit."
						isMessageOpen={isMessageEmptyOpen}
						openMessage={openMessageEmpty}
						buttonClassName={
							merchant.platformId === 3
								? classes.submitButton
								: classes.submitButtonMSI
						}
					/>

					<FlyoutMessage
						message={
							merchant.platformId === 3
								? "Please agree to processing of your personal data for the selected purposes, and agree that eFit Financial may collect, store, process and use the personal data provided by you."
								: "Please agree to processing of your personal data for the selected purposes, and agree that Member Solutions may collect, store, process and use the personal data provided by you."
						}
						isMessageOpen={isMessageCheckOpen}
						openMessage={openMessageCheckout}
						buttonClassName={
							merchant.platformId === 3
								? classes.submitButton
								: classes.submitButtonMSI
						}
					/>
				</>
			)}
		</div>
	);
};

export default Main;
