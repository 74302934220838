import React, { useMemo, useEffect, useState, useRef, useContext } from "react";
import { Grid, Divider } from "@material-ui/core";

import Title from "../../components/Title";
import InputCustom from "../../components/InputCustom";
import SelectCustom from "../../components/SelectCustom";
import DatePickerCustom from "../../components/DatePickerCustom";
import { IErrors, validate } from "./validate";
import { IOwner } from "../../model";
import countries from "../../countries.json";
import GlobalContext from "../../context/global-context";

interface IProps {
	owner: IOwner;
	number: number;
	setResponce: (owner: any, index: number) => void;
	isSumbit: boolean;
	allOwnersExist: boolean;
	setownerValidateDone: (value: boolean) => void;
}

const OwnerInformation: React.FC<IProps> = ({
	owner,
	number,
	setResponce,
	isSumbit,
	allOwnersExist,
	setownerValidateDone,
}) => {
	const { isMessageEmptyOpen, openMessageEmpty } = useContext(GlobalContext);
	const [choosedCountry, setChoosedCountry] = useState<string>("");
	const [isUsed, setIsUsed] = useState(false);
	const [ownerFullName, setOwnerFullName] = useState<string>("");
	const [percentageOfOwnership, setPercentageOfOwnership] = useState<number>(0);
	const [passportNumber, setPassportNumber] = useState<string>("");
	const [socialSecurity, setSocialSecurity] = useState<string>("");
	const [verifySocialSecurity, setVerifySocialSecurity] = useState<string>("");
	const [dateOfBirth, setDateOfBirth] = useState<string | undefined>("");
	const [ownerPhone, setOwnerPhone] = useState<string>("");
	const [ownerEmail, setOwnerEmail] = useState<string>("");
	const [ownerCountry, setOwnerCountry] = useState<string>("");
	const [ownerAddress, setOwnerAddress] = useState<string>("");
	const [ownerAddress2, setOwnerAddress2] = useState<string>("");
	const [ownerCity, setOwnerCity] = useState<string>("");
	const [ownerProvince, setOwnerProvince] = useState<string>("");
	const [ownerPostalCode, setOwnerPostalCode] = useState<string>("");
	const [isSettingDone, setIsSettingDone] = useState(false);

	const [errorsOwner, setErrorsOwner] = useState<IErrors>({});
	const myRef = useRef<HTMLHeadingElement>(null);
	const [isMessage, setIsMessage] = useState(false);

	const optionCountry = useMemo(() => {
		return countries.map((item) => ({
			label: item.name,
			value: item.code,
		}));
	}, []);

	const optionProvince = useMemo(() => {
		const country = countries.find((item) => item.code === choosedCountry);

		if (country) {
			return country.states.map((item) => ({
				label: item.name,
				value: item.code,
			}));
		}

		return [];
	}, [choosedCountry]);

	useEffect(() => {
		setErrorsOwner({});
		setIsSettingDone(false);
		setOwnerFullName(owner.fullName);
		setSocialSecurity(owner.socialInsuranceNumber.trim());
		setVerifySocialSecurity(owner.socialInsuranceNumber.trim());
		setDateOfBirth(owner.dateOfBirth);
		setOwnerPhone(owner.phone.trim());
		setOwnerCountry(owner.country.trim());
		setOwnerCity(owner.city.trim());
		setOwnerProvince(owner.state.trim());
		setOwnerPostalCode(owner.zipCode.trim());

		if (owner.country) {
			setChoosedCountry(owner.country.trim());
		}
		if (owner.passportNo) {
			setPassportNumber(owner.passportNo.trim());
		}

		if (owner.ownershipPercentage) {
			setPercentageOfOwnership(owner.ownershipPercentage);
		}

		if (owner.email) {
			setOwnerEmail(owner.email.trim());
		}

		if (owner.homeAddress) {
			setOwnerAddress(owner.homeAddress.trim());
		}

		if (owner.address2) {
			setOwnerAddress2(owner.address2.trim());
		}
		setIsSettingDone(true);
	}, []);

	useEffect(() => {
		if (!isMessageEmptyOpen && myRef.current && isMessage) {
			myRef.current.scrollIntoView();
			setIsMessage(false);
		}
	}, [isMessageEmptyOpen, isMessage]);

	useEffect(() => {
		if (allOwnersExist) {
			const isValid = validate({
				ownerFullName,
				percentageOfOwnership,
				socialSecurity,
				passportNumber,
				verifySocialSecurity,
				dateOfBirth,
				ownerPhone,
				ownerEmail,
				ownerCountry,
				ownerAddress,
				ownerCity,
				ownerProvince,
				ownerPostalCode,
			});

			setErrorsOwner(isValid);

			if (isSumbit) {
				handleSubmit(isValid);
			}
		}
	}, [
		allOwnersExist,
		isSumbit,
		percentageOfOwnership,
		socialSecurity,
		verifySocialSecurity,
		dateOfBirth,
		ownerFullName,
		ownerPhone,
		ownerEmail,
		ownerCountry,
		ownerAddress,
		ownerCity,
		ownerProvince,
		ownerPostalCode,
		passportNumber,
	]);

	const handleSubmit = (isValid: any) => {
		setownerValidateDone(false);
		if (!isUsed) {
			if (Object.keys(isValid).length > 0 && myRef.current) {
				openMessageEmpty(true);
				setIsMessage(true);
				setownerValidateDone(true);
				return;
			}
			if (Object.keys(isValid).length === 0) {
				setIsUsed(true);
				setownerValidateDone(true);
				setResponce(
					{
						fullName: ownerFullName,
						ownershipPercentage: percentageOfOwnership,
						socialInsuranceNumber: socialSecurity,
						dateOfBirth,
						passportNo: passportNumber,
						phone: ownerPhone,
						homeAddress: ownerAddress,
						city: ownerCity,
						zipCode: ownerPostalCode,
						email: ownerEmail,
						country: ownerCountry,
						state: ownerProvince,
						address2: ownerAddress2 ? ownerAddress2 : null,
						verifiedSocialInsuranceNumber: verifySocialSecurity,
					},
					number
				);
			}
		}
	};

	return (
		<>
			{isSettingDone && (
				<>
					<Grid item xs={12}>
						<Title label={`Owner ${number + 1}`} />
					</Grid>
					<Grid item xs={12} id={`owner-${number}`}>
						<Grid container spacing={1} justify="space-between">
							<Grid item xs={12} md={5}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<InputCustom
											error={errorsOwner.ownerFullName}
											label="Owner Full Name *"
											name="ownerFullName"
											required
											defaultValue={ownerFullName}
											onBlur={(event) => {
												setOwnerFullName(event.target.value.trim());

												const isValid = validate({
													ownerFullName: event.target.value.trim(),
												});

												setErrorsOwner({
													...errorsOwner,
													ownerFullName: isValid.ownerFullName,
												});
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputCustom
											error={errorsOwner.percentageOfOwnership}
											label="Percentage of Ownership *"
											name="percentageOfOwnership"
											type="number"
											autoComplete="new-password"
											max={100}
											min={0}
											required
											defaultValue={percentageOfOwnership}
											onBlur={(event) => {
												setPercentageOfOwnership(Number(event.target.value));

												const isValid = validate({
													percentageOfOwnership: event.target.value,
												});

												setErrorsOwner({
													...errorsOwner,
													percentageOfOwnership: isValid.percentageOfOwnership,
												});
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputCustom
											error={errorsOwner.socialSecurity}
											label={
												ownerCountry === "USA"
													? "Social Security (SSN) / Social Insurance Number (SIN) *"
													: "Social Security (SSN) / Social Insurance Number (SIN)"
											}
											name="socialSecurity"
											type="password"
											autoComplete="new-password"
											maxLength={9}
											required
											maskInput
											onPaste={(event) => event.preventDefault()}
											defaultValue={socialSecurity}
											onBlur={(event) => {
												setSocialSecurity(event.target.value.trim());

												const isValid = validate({
													socialSecurity: event.target.value.trim(),
													ownerCountry: ownerCountry,
													passportNumber: passportNumber,
												});

												setErrorsOwner({
													...errorsOwner,
													socialSecurity: isValid.socialSecurity,
													passportNumber: isValid.passportNumber,
												});
											}}
										/>
									</Grid>
									<Grid item xs={12} ref={myRef}>
										<InputCustom
											error={errorsOwner.verifySocialSecurity}
											label={
												ownerCountry === "USA"
													? "Verify Social Security (SSN) / Social Insurance Number (SIN) *"
													: "Verify Social Security (SSN) / Social Insurance Number (SIN)"
											}
											name="verifySocialSecurity"
											type="password"
											autoComplete="new-password"
											maxLength={9}
											required
											maskInput
											onPaste={(event) => event.preventDefault()}
											defaultValue={verifySocialSecurity}
											onBlur={(event) => {
												setVerifySocialSecurity(event.target.value.trim());

												const isValid = validate({
													verifySocialSecurity: event.target.value.trim(),
													socialSecurity,
												});

												setErrorsOwner({
													...errorsOwner,
													verifySocialSecurity: isValid.verifySocialSecurity,
												});
											}}
										/>
									</Grid>
									{ownerCountry === "CAN" && (
										<Grid item xs={12} ref={myRef}>
											<InputCustom
												error={errorsOwner.passportNumber}
												label="Passport Number"
												name="passportNumber"
												type="password"
												autoComplete="new-password"
												maxLength={8}
												required
												maskInput
												onPaste={(event) => event.preventDefault()}
												defaultValue={passportNumber}
												onBlur={(event) => {
													setPassportNumber(event.target.value.trim());

													const isValid = validate({
														passportNumber: event.target.value.trim(),
														ownerCountry: ownerCountry,
														socialSecurity: socialSecurity,
													});

													setErrorsOwner({
														...errorsOwner,
														socialSecurity: isValid.socialSecurity,
														passportNumber: isValid.passportNumber,
													});
												}}
											/>
										</Grid>
									)}
									<Grid item xs={12}>
										<DatePickerCustom
											label="Date of Birth *"
											required
											locale="us"
											value={dateOfBirth}
											error={errorsOwner.dateOfBirth}
											onChange={(value) => {
												setDateOfBirth(value);
												const isValid = validate({
													dateOfBirth: value,
												});
												setErrorsOwner({
													...errorsOwner,
													dateOfBirth: isValid.dateOfBirth,
												});
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputCustom
											error={errorsOwner.ownerPhone}
											label="Owner Phone *"
											name="ownerPhone"
											autoComplete="new-password"
											type="tel"
											maxLength={15}
											required
											defaultValue={ownerPhone}
											onBlur={(event) => {
												setOwnerPhone(event.target.value.trim());

												const isValid = validate({
													ownerPhone: event.target.value.trim(),
												});

												setErrorsOwner({
													...errorsOwner,
													ownerPhone: isValid.ownerPhone,
												});
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={5}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<InputCustom
											error={errorsOwner.ownerEmail}
											label="Owner Email *"
											name="ownerEmail"
											type="email"
											autoComplete="new-password"
											maxLength={150}
											required
											defaultValue={ownerEmail}
											onBlur={(event) => {
												setOwnerEmail(event.target.value.trim());

												const isValid = validate({
													ownerEmail: event.target.value.trim(),
												});

												setErrorsOwner({
													...errorsOwner,
													ownerEmail: isValid.ownerEmail,
												});
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<SelectCustom
											error={errorsOwner.ownerCountry}
											label="Country *"
											name="country"
											options={optionCountry}
											value={ownerCountry}
											onChange={(event) => {
												setChoosedCountry(event.target.value);
												setOwnerCountry(event.target.value);
												setOwnerProvince("");
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputCustom
											error={errorsOwner.ownerAddress}
											label="Home Address *"
											name="address"
											autoComplete="new-password"
											title="Please enter your physical address. P.O. boxes are not allowed"
											required
											defaultValue={ownerAddress}
											onBlur={(event) => {
												setOwnerAddress(event.target.value.trim());

												const isValid = validate({
													ownerAddress: event.target.value.trim(),
												});

												setErrorsOwner({
													...errorsOwner,
													ownerAddress: isValid.ownerAddress,
												});
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputCustom
											error={errorsOwner.ownerAddress2}
											label="Address 2"
											name="address2"
											autoComplete="new-password"
											title="Please enter your physical address. P.O. boxes are not allowed"
											defaultValue={ownerAddress2}
											onBlur={(event) => {
												setOwnerAddress2(event.target.value.trim());

												const isValid = validate({
													ownerAddress2: event.target.value.trim(),
												});

												setErrorsOwner({
													...errorsOwner,
													ownerAddress2: isValid.ownerAddress2,
												});
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputCustom
											error={errorsOwner.ownerCity}
											label="City *"
											name="city"
											required
											autoComplete="new-password"
											defaultValue={ownerCity}
											onBlur={(event) => {
												setOwnerCity(event.target.value.trim());

												const isValid = validate({
													ownerCity: event.target.value.trim(),
												});

												setErrorsOwner({
													...errorsOwner,
													ownerCity: isValid.ownerCity,
												});
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<SelectCustom
											error={errorsOwner.ownerProvince}
											label="State/Province *"
											name="province"
											options={optionProvince}
											value={ownerProvince}
											disabled={!choosedCountry}
											onChange={(event) => {
												setOwnerProvince(event.target.value);
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<InputCustom
											error={errorsOwner.ownerPostalCode}
											label="Zip/Postal Code *"
											name="postalCode"
											autoComplete="new-password"
											required
											defaultValue={ownerPostalCode}
											onBlur={(event) => {
												setOwnerPostalCode(event.target.value.trim());

												const isValid = validate({
													ownerPostalCode: event.target.value.trim(),
												});

												setErrorsOwner({
													...errorsOwner,
													ownerPostalCode: isValid.ownerPostalCode,
												});
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} style={{ padding: "30px 0 30px" }}>
						<Divider />
					</Grid>
				</>
			)}
		</>
	);
};

export default OwnerInformation;
