import * as React from "react";
import { useReducer } from "react";
import GlobalContext from "./global-context";
import {
  globalReducer,
  defaultState,
  SET_AUTH,
  OPEN_MESSAGE,
  OPEN_MESSAGE_CHECK,
  OPEN_MESSAGE_EMPTY,
  OPEN_MESSAGE_INVALID,
  OPEN_MESSAGE_SUBMITTED,
} from "./reducers";
interface IProps {}

const GlobalState: React.FC<IProps> = ({ children }) => {
  const [globalState, dispatch] = useReducer(globalReducer, defaultState);

  const setAuth = (auth: string) => {
    dispatch({ type: SET_AUTH, payload: auth });
  };

  const openMessage = (open: boolean) => {
    dispatch({ type: OPEN_MESSAGE, payload: open });
  };

  const openMessageCheckout = (open: boolean) => {
    dispatch({ type: OPEN_MESSAGE_CHECK, payload: open });
  };

  const openMessageEmpty = (open: boolean) => {
    dispatch({ type: OPEN_MESSAGE_EMPTY, payload: open });
  };

  const openMessageInvalid = (open: boolean) => {
    dispatch({ type: OPEN_MESSAGE_INVALID, payload: open });
  };

  const openMessageSubmitted = (open: boolean) => {
    dispatch({ type: OPEN_MESSAGE_SUBMITTED, payload: open });
  };

  return (
    <GlobalContext.Provider
      value={{
        ...globalState,
        dispatchGlobal: dispatch,
        setAuth,
        openMessage,
        openMessageCheckout,
        openMessageEmpty,
        openMessageInvalid,
        openMessageSubmitted,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
