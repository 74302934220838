import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Container, Paper, Typography } from "@material-ui/core";
import Passport from "../images/SamplePassport.png";
import { useStyles } from "./styles";
import Navbar from "../components/Navbar";
import Title from "../components/Title";

const Result: React.FC = () => {
	const params = useParams<{ id: string; passport: string }>();
	const classes = useStyles();

	useEffect(() => {
		document.title =
			params.id === "efit"
				? "eFit - Verification Form"
				: "MSI - Verification Form";
	}, [params]);

	return (
		<div style={{ padding: 20, overflow: "hidden" }}>
			<Navbar platform={params.id} />
			<Container maxWidth="lg">
				<Grid container spacing={1} style={{ padding: "70px 10px 10px" }}>
					<Grid item xs={12}>
						<Paper
							variant="outlined"
							style={{
								borderColor: params.id === "efit" ? "#6084FB" : "#f8a433",
								padding: 30,
							}}
						>
							{params.id === "msi" && !params.passport && (
								<Title
									label="Submission complete! Thank you for taking the time to review and update your information. If you have any questions, please contact client services at 1-888-277-4407 or"
									link="clientservices@membersolutions.com"
									align="center"
									variant="title"
								/>
							)}
							{params.id !== "msi" && (
								<Title
									label="Thank you for taking the time to review and update your information. If you have any questions, please contact client services at 1-888-277-4407 or"
									link="contact@efitfinancial.com"
									align="center"
									variant="title"
									className={classes.efitLink}
								/>
							)}
							{params.id === "msi" && params.passport && (
								<>
									<Title
										label="Thank you for taking the time to review and update your information."
										align="center"
										variant="title"
									/>
									<Typography
										variant="h4"
										align="center"
										style={{ color: "red", paddingTop: 20, paddingBottom: 20 }}
									>
										<strong>
											IMPORTANT! There is one last step to complete your
											verification process.
										</strong>
									</Typography>
									<Typography variant="h5" align="center">
										<strong>
											ACTION NEEDED: Please send a copy of page 1 and page 2 of
											the passport(s) for all owner(s) who provided their
											passport number via our Secure Portal.
										</strong>
									</Typography>
									<Typography
										variant="h5"
										align="center"
										style={{ marginTop: 20, marginBottom: 20 }}
									>
										<strong>1.</strong> Make a copy of page 1 and 2 of your
										passport as shown below:
										<br />
										<img
											style={{ marginTop: 20 }}
											src={Passport}
											alt="passport"
											width="50%"
										/>
									</Typography>
									<Typography
										align="left"
										variant="h5"
										style={{ marginTop: 20, marginBottom: 20 }}
									>
										<strong>2. </strong>
										<a
											target="_blank"
											rel="noreferrer"
											href="https://membersolutions.zixportal.com/s/login?=membersolutions"
										>
											Click here
										</a>{" "}
										to go to our Secure Portal to register and submit your copy
										of your passport to Member Solutions.
										<br />
										<div style={{ paddingLeft: 40 }}>
											• If you’ve already registered, use the login credentials
											you created to log in and submit the necessary
											documentation.
											<br />• If you are registering for the first time, you’ll
											receive a confirmation email to verify your email address.
											Please verify your email address by clicking the verify
											button within the confirmation email. After verifying your
											email address, log in with your new credentials.{" "}
										</div>
									</Typography>
									<Typography
										variant="h5"
										align="left"
										style={{ marginTop: 20 }}
									>
										<strong>3.</strong> Compose an email to{" "}
										<span style={{ color: "#f38b00" }}>
											securedocs@membersolutions.com
										</span>{" "}
										in the Portal and attach the copy of page 1 and page 2 of
										your passport.
										<br />
										<br />
										Should you have questions or need assistance, please contact
										our Client Services team at 888.277.4407 or{" "}
										<span>
											<a
												href="mailto:clientservices@membersolutions.com"
												target="_blank"
												rel="noreferrer"
												style={{ color: "#f38b00" }}
											>
												clientservices@membersolutions.com
											</a>
										</span>
										.
									</Typography>
								</>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Result;
