import React from "react";
import { Grid, Link as LinkButton, Box } from "@material-ui/core";

import Title from "../../components/Title";
import Info from "../../components/Info";
import { useStyles } from "../styles";

interface IProps {
  platform: string;
}

const DescriptionAndIntentToCollect: React.FC<IProps> = ({ platform }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Title
          label={"Please Verify Business and Owner Information"}
          align="center"
          variant="title"
        />
      </Grid>
      <Grid item xs={12}>
        <Info text="We are changing internal payment processing providers to deliver an enhanced payment experience to you and your members. As part of this upgrade process, the business and business owner information below needs to be verified." />
      </Grid>
      <Grid item xs={12}>
        <Info text="Please review all fields below, make all necessary updates, and confirm all information is up to date. We appreciate your time and thank you for your cooperation." />
      </Grid>
      <Grid item xs={12}>
        <Title label="Why does my information need to be verified?" />
      </Grid>
      <Grid item xs={12}>
        <Info text="As we are making changes to offer better and speedier service, we need to ensure all information on file is correct, complete, and up to date for our clients. We require that all complete this process of reviewing and confirming data on file. Failure to do so could lead to an interruption in payment processing and payment delivery." />
      </Grid>
      <Grid item xs={12}>
        <Title
          label={
            platform === "efit"
              ? "How does eFit Financial keep my personal data safe?"
              : "How does Member Solutions keep my personal data safe?"
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Info
          text={
            platform === "efit"
              ? "The security of your personal data is extremely important to us. At eFit Financial, we follow industry standards to protect the personal data we both receive and transmit through security technology like HTTPS and Transport Layer Security. We also encrypt all sensitive data that we store using an AES 256 encryption key, the current encryption standard used by most government agencies and private sector IT firms."
              : "The security of your personal data is extremely important to us. At Member Solutions, we follow industry standards to protect the personal data we both receive and transmit through security technology like HTTPS and Transport Layer Security. We also encrypt all sensitive data that we store using an AES 256 encryption key, the current encryption standard used by most government agencies and private sector IT firms."
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex">
          <Info text="For questions regarding your privacy please refer to our" />
          <LinkButton
            target="_blank"
            href={
              platform === "efit"
                ? " https://efitfinancial.com/privacy-policy"
                : " https://membersolutions.com/privacy-policy/"
            }
            style={{ marginLeft: 5 }}
            className={platform === "efit" ? classes.efitLink : undefined}
          >
            privacy policy
          </LinkButton>
        </Box>
      </Grid>
    </>
  );
};

export default DescriptionAndIntentToCollect;
