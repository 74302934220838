import React from "react";

import Cube from "../../images/blue-cube-bkgd-illustration.svg";

const Footer: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        position: "relative",
        zIndex: -1,
      }}
    >
      <img
        height="180px"
        src={Cube}
        style={{ position: "absolute", top: "-150px", left: "-100px" }}
        alt={"Cube"}
      />
    </div>
  );
};

export default Footer;
