import {
  createMuiTheme,
  responsiveFontSizes,
  lighten,
} from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

import OpenSansRegular from "../fonts/OpenSans-Regular.ttf";

const openSans = {
  fontFamily: "OpenSans",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    local('OpenSans'),
    local('OpenSans-Regular'),
    url(${OpenSansRegular}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const COLORS = {
  black: "#455761", // cur
  error: "#DE350A",
  info: "#1850BA",
  primary: "#022169", //cur
  secondary: "#bcbec0", //cur
  success: "#00984C",
  white: "#f8f8f8",
  warning: "#f38b00", // cur
};

const bgPercent = 0.9;
const bgPercentLable = 0.8;
const bgHoveredPercent = 0.7;

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: lighten(COLORS.primary, bgPercent),
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
        "@font-face": [openSans],
      },
    },
    MuiDialog: {
      paper: {
        border: "none!important",
      },
    },
    MuiButtonBase: {
      root: {
        backgroundColor: "none",
        color: "auto",
      },
    },
    MuiButton: {
      root: {
        padding: "8px 24px!important",
        borderRadius: "1px!important",
        boxShadow: "none!important",
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "1.5rem!important",
        textTransform: "none",
      },
      textPrimary: {
        fontWeight: 500,
        backgroundColor: "transparent!important",
        "&:hover": {
          backgroundColor: `${lighten(COLORS.primary, bgPercent)}!important`,
        },
      },
      textSecondary: {
        fontWeight: 500,
      },
      outlined: {
        backgroundColor: "transparent!important",
        "&:hover": {
          backgroundColor: `${lighten(
            COLORS.primary,
            bgHoveredPercent
          )}!important`,
        },
      },
      outlinedSizeSmall: {
        padding: "3px 9px!important",
      },
    },
    MuiIconButton: {
      root: {
        backgroundColor: "transparent",
      },
      colorPrimary: {
        padding: 6,
        backgroundColor: lighten(COLORS.primary, bgPercent),
        "&:hover": {
          backgroundColor: lighten(COLORS.primary, bgHoveredPercent),
        },
      },
      colorSecondary: {
        padding: 6,
        backgroundColor: lighten(COLORS.secondary, bgPercent),
        "&:hover": {
          backgroundColor: lighten(COLORS.secondary, bgHoveredPercent),
        },
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: "300!important" as any,
      },
    },
    MuiInputLabel: {
      root: {
        color: "#888888",
        fontWeight: 400,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "transparent",
        color: " #888888",
        border: `solid 2px #dddddd`,
        "&:hover": {
          backgroundColor: COLORS.white,
        },
      },
      underline: {
        borderBottom: COLORS.primary,
        "&:before": {
          borderBottom: `1px solid #ddd`,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `1px solid ${COLORS.warning} !important`,
        },
        // '&$disabled:before': {
        //   borderBottom: `1px dotted ${COLORS.primary}`,
        // },
      },
    },
    MuiLink: {
      root: {
        fontWeight: "600!important" as any,
        color: "#f38b00",
        fontSize: "18px",
        "&:hover": {
          color: "#f8a433",
        },
        [breakpoints.down("md")]: {
          fontSize: 12,
        },
      },
    },
    MuiMenu: {
      paper: {
        border: "none",
        maxWidth: 500,
      },
    },
    MuiMenuItem: {
      root: {
        overflow: "visible",
        whiteSpace: "normal",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "0!important",
        "&.input-secure": {
          textSecurity: "disc",
          webkitTextSecurity: "disc",
        },
        "&.read-only:hover input": {
          cursor: "default",
        },
        "&.read-only $notchedOutline, &.read-only:hover $notchedOutline": {
          borderColor: lighten(COLORS.black, bgPercent),
        },
      },
    },
    MuiPaper: {
      root: {
        border: `1px solid ${lighten(COLORS.black, 0.8)}`,
      },
    },
    MuiRadio: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiTabs: {
      root: {
        minHeight: 40,
      },
      indicator: {
        height: 4,
        borderRadius: 2,
      },
    },
    MuiTab: {
      root: {
        paddingLeft: "8px!important",
        paddingRight: "8px!important",
        // lineHeight: '1.5rem!important',
        color: `${lighten(COLORS.black, 0.4)}!important`,
        backgroundColor: "transparent!important",
        fontSize: "1.05rem",
        fontWeight: 300,
        minWidth: "100px!important",
        minHeight: 40,
        borderBottom: `1px solid #ddd!important`,
        textTransform: "none",
        "&$selected": {
          color: `${COLORS.black}!important`,
          fontWeight: 500,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
        fontWeight: 300,
        "&$sizeSmall": {
          [breakpoints.down("md")]: {
            padding: 4,
          },
          [breakpoints.down("sm")]: {
            padding: 2,
          },
        },
        [breakpoints.down("md")]: {
          fontSize: 12,
        },
      },
    },
    MuiTablePagination: {
      toolbar: {
        alignItems: "center !important",
      },
      caption: {
        [breakpoints.down("md")]: {
          fontSize: 12,
        },
      },
      select: {
        [breakpoints.down("md")]: {
          fontSize: 12,
        },
      },
      selectRoot: {
        [breakpoints.down("md")]: {
          marginRight: 2,
        },
      },
    },
    MuiTypography: {
      subtitle2: {
        color: "#888888",
        // fonFamily: open sans
        fontSize: "18px",
        lineHeight: "25px",
      },
      body1: {
        fontSize: 18,
        color: "#888888",
        fontWeight: 400,
        [breakpoints.down("md")]: {
          fontSize: 12,
        },
      },
      body2: {
        fontSize: 14,
        color: "#888888",
        fontWeight: 300,
        [breakpoints.down("md")]: {
          fontSize: 12,
        },
      },
      caption: {
        color: lighten(COLORS.black, 0.5),
      },
      h6: {
        fontWeight: 400,
      },
      button: {
        padding: "3px 10px",
        backgroundColor: `${lighten(COLORS.primary, bgPercentLable)}!important`,
        textTransform: "none",
        fontWeight: 300,
      },
      h4: {
        color: "#022169",
        fontSize: 24,
        // fontFamily: "OpenSans-Semibold",
      },
      h1: {
        color: "#022169",
        fontSize: "42px !important",
        fontWeight: 700,
        // fontFamily: "OpenSans-Semibold",
      },
      h2: {
        color: "#022169",
        fontSize: "32px !important",
        fontWeight: 700,
        // fontFamily: "OpenSans-Semibold",
      },
    },
    MuiToolbar: {
      root: {
        alignItems: "stretch",
      },
    },
    MuiTableRow: {
      root: {
        borderBottom: `1px solid ${lighten(COLORS.black, bgPercent)}`,
        "&:hover": {
          backgroundColor: `${lighten(COLORS.primary, bgPercent)}!important`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: `${COLORS.black}!important`,
        fontSize: 14,
      },
    },
  },
  palette: {
    action: {
      disabledBackground: "#dddddd",
      disabled: "#acacac",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    common: {
      black: COLORS.black,
      white: COLORS.white,
    },
    divider: lighten(COLORS.black, 0.9),
    error: {
      main: COLORS.error,
    },
    info: {
      main: COLORS.info,
    },
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: lighten(COLORS.secondary, 0.4),
    },
    success: {
      main: COLORS.success,
    },
    text: {
      primary: COLORS.black,
      secondary: "",
    },
  },
  props: {
    MuiButton: {
      color: "primary",
      variant: "contained",
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiTextField: {
      size: "small",
      variant: "filled",
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: ["OpenSans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
      ","
    ),
  },
});

export const responsiveFontSizesTheme = responsiveFontSizes(theme);
