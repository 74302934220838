import React from "react";
import { Link } from "react-router-dom";

import LogoMSI from "../../images/msi-logo-fullcolor.svg";
import LogoEfit from "../../images/eFit-logo.png";
import Cube from "../../images/blue-cube-bkgd-illustration.svg";

interface IProps {
  platform: string;
}

const Navbar: React.FC<IProps> = ({ platform }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        position: "relative",
        zIndex: -1,
      }}
    >
      {platform && (
        <Link
          to={platform === "efit" ? "/login/efit" : "/login/msi"}
          style={{ cursor: "pointer" }}
        >
          <img
            height="80px"
            src={platform === "efit" ? LogoEfit : LogoMSI}
            alt={platform}
          />
        </Link>
      )}
      <img
        height="180px"
        src={Cube}
        style={{ position: "absolute", right: "-100px" }}
        alt="Cube"
      />
    </div>
  );
};

export default Navbar;
