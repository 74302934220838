import React from "react";
import { IGlobalState } from "./state.model";

const GlobalContext = React.createContext<IGlobalState>({
  auth: "",
  isMessageOpen: false,
  isMessageCheckOpen: false,
  isMessageEmptyOpen: false,
  isMessageInvalidOpen: false,
  isMessageSubmittedOpen: false,
  setAuth: (auth) => {},
  openMessage: (open) => {},
  openMessageCheckout: (open) => {},
  openMessageEmpty: (open) => {},
  openMessageInvalid: (open) => {},
  openMessageSubmitted: (open) => {},
});

export default GlobalContext;
