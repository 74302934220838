import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { FormikTouched } from "formik";

interface IProps {
  autoFocus?: boolean;
  error?: string;
  endAdornment?: any;
  label: string;
  name: string;
  options: { label: string; value: string; disabled?: boolean }[];
  readOnly?: boolean;
  required?: boolean;
  // theme: Theme;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  type?: string;
  value: string;
  disabled?: boolean;
  onBlur?: (e: any) => void;
  onChange?: (event: any) => void;
}

const SelectCustom: React.FC<IProps> = ({
  autoFocus,
  error,
  endAdornment,
  label,
  name,
  options,
  readOnly,
  required,
  touched,
  value,
  disabled,
  onBlur,
  onChange,
}) => {
  return (
    <>
      <InputLabel htmlFor={name} color="secondary">
        {label}
      </InputLabel>
      <FormControl
        error={Boolean(error)}
        fullWidth
        margin="dense"
        variant="outlined"
      >
        <Select
          autoFocus={autoFocus}
          className={readOnly ? "read-only" : ""}
          endAdornment={endAdornment}
          fullWidth
          name={name}
          readOnly={readOnly}
          required={required}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error ? error : ""}</FormHelperText>
      </FormControl>
    </>
  );
};

export default SelectCustom;
