import React from "react";
import { Typography, Link as LinkButton } from "@material-ui/core";

interface IProps {
  label: string;
  align?: "left" | "center" | "right" | "justify" | "inherit";
  variant?: "title" | "subtitle";
  link?: string;
  linkText?: string;
  className?: any;
}

const Title: React.FC<IProps> = ({
  label,
  align = "left",
  variant,
  link,
  linkText,
  className,
}) => {
  return (
    <>
      {link ? (
        <Typography align={align} variant={variant === "title" ? "h1" : "h2"}>
          {label}{" "}
          {link && (
            <LinkButton
              target="_blank"
              href={link.indexOf("@") !== -1 ? `mailto:${link}` : link}
              style={{ fontSize: 42 }}
              className={className}
            >
              {link.indexOf("@") !== -1 ? link : linkText}
            </LinkButton>
          )}
        </Typography>
      ) : (
        <Typography align={align} variant={variant === "title" ? "h1" : "h2"}>
          {label}
        </Typography>
      )}
    </>
  );
};

export default Title;
