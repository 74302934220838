export const validate = (values: any) => {
  const errors: any = {};

  if (!values.externalId) {
    errors.externalId = "This field is required*";
  }

  if (!values.bankAccount) {
    errors.bankAccount = "This field is required*";
  }

  if (!values.zipCode) {
    errors.zipCode = "This field is required*";
  }

  return errors;
};
