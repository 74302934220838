import { IGlobalState, IAction } from "./state.model";

export const SET_AUTH = "SET_AUTH";

export const OPEN_MESSAGE = "OPEN_MESSAGE";
export const OPEN_MESSAGE_CHECK = "OPEN_MESSAGE_CHECK";
export const OPEN_MESSAGE_EMPTY = "OPEN_MESSAGE_EMPTY";
export const OPEN_MESSAGE_INVALID = "OPEN_MESSAGE_INVALID";
export const OPEN_MESSAGE_SUBMITTED = "OPEN_MESSAGE_SUBMITTED";
export const SET_OWNERS = "SET_OWNERS";

export const defaultState = {
  auth: "",
  isMessageOpen: false,
  isMessageCheckOpen: false,
  isMessageEmptyOpen: false,
  isMessageInvalidOpen: false,
  isMessageSubmittedOpen: false,
} as IGlobalState;

export const globalReducer = (state: IGlobalState, action: IAction) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        auth: action.payload,
      };

    case OPEN_MESSAGE:
      return {
        ...state,
        isMessageOpen: action.payload,
      };

    case OPEN_MESSAGE_CHECK:
      return {
        ...state,
        isMessageCheckOpen: action.payload,
      };

    case OPEN_MESSAGE_EMPTY:
      return {
        ...state,
        isMessageEmptyOpen: action.payload,
      };

    case OPEN_MESSAGE_INVALID:
      return {
        ...state,
        isMessageInvalidOpen: action.payload,
      };

    case OPEN_MESSAGE_SUBMITTED:
      return {
        ...state,
        isMessageSubmittedOpen: action.payload,
      };

    default:
      return state;
  }
};
