import axios from "axios";

const path = process.env.REACT_APP_API_URL;

export const getMerchant = async (id: string, authToken: string) => {
  const { data } = await axios.get(
    `${path}merchantVerification/merchant?externalId=${id}&authToken=${encodeURIComponent(authToken)}`
  );

  return data;
};

export const setMerchant = async (merchant: any, authToken: string) => {
  const { data } = await axios.post(
    `${path}merchantVerification?authToken=${encodeURIComponent(authToken)}`,
    merchant
  );

  return data;
};

export const getToken = async (
  externalId: string,
  bankAccount: string,
  zipCode: string
) => {
  const { data } = await axios.get(
    `${path}auth/token?externalId=${externalId}&bankAccount=${bankAccount}&zipCode=${zipCode}`
  );

  return data;
};
