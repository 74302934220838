import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";

import { useStyles } from "./styles";
import { validate } from "./validate";
import FlyoutMessage from "./FlyoutMessage";
import { getToken, getMerchant } from "../services";
import GlobalContext from "../context/global-context";
import InputCustom from "../components/InputCustom";
import LoadingBtn from "../components/LoadingBtn";
import Title from "../components/Title";
import Info from "../components/Info";
import Navbar from "../components/Navbar";

const Auth: React.FC = () => {
	const history = useHistory();
	const classes = useStyles();
	const params = useParams<{ id: string }>();

	const {
		setAuth,
		isMessageOpen,
		openMessage,
		isMessageSubmittedOpen,
		openMessageSubmitted,
	} = useContext(GlobalContext);

	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState<any>({});
	const [externalId, setExternalId] = useState("");
	const [bankAccount, setBankAccount] = useState("");
	const [zipCode, setZipCode] = useState("");

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const isValid = validate({ externalId, bankAccount, zipCode });
		setErrors(isValid);

		if (Object.keys(isValid).length === 0 && isValid.constructor === Object) {
			try {
				const data = await getToken(externalId, bankAccount, zipCode);

				const merchant = await getMerchant(externalId, data.auth);
				if (merchant.isFormSubmitted) {
					openMessageSubmitted(true);
				} else {
					setAuth(data.auth);
					history.push(`/merchant/${externalId}`);
				}
			} catch (error) {
				openMessage(true);
			}
		}
	};

	useEffect(() => {
		document.title =
			params.id === "efit"
				? "eFit - Authentication Form"
				: "MSI - Authentication Form";

		const favicon = document.querySelector("#favicon");
		if (favicon && params.id === "msi") {
			favicon.setAttribute("href", "/msi-logo-fullcolor-cube.png");
		}

		if (favicon && params.id === "efit") {
			favicon.setAttribute("href", "/efit-logo-icon.png");
		}
	}, [params]);

	return (
		<div style={{ padding: 20, overflow: "hidden" }}>
			<Navbar platform={params.id} />
			<Container maxWidth="lg">
				<Grid
					container
					spacing={3}
					style={{ padding: 50 }}
					justify="center"
					alignItems="center"
				>
					<Grid item xs={12}>
						<Title
							label={"Update Your Information Today"}
							align="center"
							variant="title"
						/>
					</Grid>
					<Grid item xs={12}>
						{params.id === "efit" ? (
							<Info
								text="We are changing our internal payment processing provider to deliver an enhanced experience to you and your members. As part of this upgrade process, your business information needs to be verified to ensure all payments continue to process and be deposited in a timely manner. If you have any questions regarding this process, please contact client services at 1-877-772-3348 or"
								link="contact@efitfinancial.com"
								className={classes.efitLink}
							/>
						) : (
							<Info
								text="We are changing our internal payment processing provider to deliver an enhanced experience to you and your members. As part of this upgrade process, your business information needs to be verified to ensure all payments continue to process and be deposited in a timely manner. If you have any questions regarding this process, please contact client services at 1-888-277-4407 or email us at"
								link="client@membersolutions.com"
							/>
						)}
					</Grid>
					<Grid item xs={12}>
						<Info text="Before we get started, we will need some information to verify your identity." />
					</Grid>
					<Grid item xs={12} md={6}>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={3} justify="center">
								<Grid item xs={12}>
									<InputCustom
										label={
											params.id === "efit"
												? "Club ID"
												: "Client ID (must be six digits)"
										}
										name="externalId"
										maxLength={6}
										error={errors.externalId}
										value={externalId}
										required
										pattern={params.id === "efit" ? "[0-9]+" : ".{6}"}
										onChange={(event) => setExternalId(event.target.value)}
										title={
											params.id === "efit"
												? undefined
												: "The Client ID entered must be six digits. For example, if your account is 123a, please enter 00123a."
										}
									/>
								</Grid>

								<Grid item xs={12}>
									<InputCustom
										label="Last Four Digits of Bank Account on File"
										name="bankAccount"
										maxLength={6}
										error={errors.bankAccount}
										value={bankAccount}
										required
										pattern="[0-9]{4}"
										onChange={(event) => setBankAccount(event.target.value)}
									/>
								</Grid>

								<Grid item xs={12}>
									<InputCustom
										label="Zip/Postal Code"
										name="bankAccount"
										error={errors.zipCode}
										value={zipCode}
										required
										onChange={(event) => setZipCode(event.target.value)}
									/>
								</Grid>

								<Grid item xs={12}>
									<LoadingBtn
										fetching={isLoading}
										className={
											params.id === "efit"
												? classes.submitButton
												: classes.submitButtonMSI
										}
										label="Submit"
										fullWidth
										type="submit"
									/>
								</Grid>
							</Grid>
						</form>
					</Grid>
					<Grid item xs={12}>
						<Info
							text="For questions regarding your privacy please refer to our"
							link={
								params.id === "efit"
									? "https://efitfinancial.com/privacy-policy/"
									: "https://membersolutions.com/privacy-policy/"
							}
							linkText="privacy policy"
							center
							className={params.id === "efit" ? classes.efitLink : undefined}
						/>
					</Grid>
				</Grid>
				{params.id === "efit" ? (
					<FlyoutMessage
						message="Some of the information entered does not match our records. Please try again. If you have trouble logging in, please contact client services at 1-877-772-3348 or "
						link="contact@efitfinancial.com"
						secondMessage="if you want to make further changes."
						isMessageOpen={isMessageOpen}
						openMessage={openMessage}
						className={classes.efitLink}
						buttonClassName={classes.submitButton}
					/>
				) : (
					<FlyoutMessage
						message="Some of the information entered does not match our records. Please try again. If you have trouble logging in, please contact client services at 1-888-277-4407 or "
						link="client@membersolutions.com"
						secondMessage="if you want to make further changes."
						isMessageOpen={isMessageOpen}
						openMessage={openMessage}
						buttonClassName={classes.submitButtonMSI}
					/>
				)}
				{params.id === "efit" ? (
					<FlyoutMessage
						message="Your information has been verified. Please contact client services at 1-877-772-3348 or "
						link="contact@efitfinancial.com"
						secondMessage="if you want to make further changes."
						isMessageOpen={isMessageSubmittedOpen}
						openMessage={openMessageSubmitted}
						className={classes.efitLink}
						buttonClassName={classes.submitButton}
					/>
				) : (
					<FlyoutMessage
						message="Your information has been verified. Please contact client services at 1-888-277-4407 or "
						link="client@membersolutions.com"
						secondMessage="if you want to make further changes."
						isMessageOpen={isMessageSubmittedOpen}
						openMessage={openMessageSubmitted}
						buttonClassName={classes.submitButtonMSI}
					/>
				)}
			</Container>
		</div>
	);
};

export default Auth;
