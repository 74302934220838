import React from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	Button,
	Grid,
	Box,
} from "@material-ui/core/";

import Info from "../components/Info";

interface IProps {
	message: string;
	link?: string;
	linkText?: string;
	secondMessage: string;
	isMessageOpen: boolean;
	openMessage: (open: boolean) => void;
	className?: any;
	buttonClassName: any;
}

const Message: React.FC<IProps> = ({
	message,
	link,
	secondMessage,
	isMessageOpen,
	linkText,
	openMessage,
	className,
	buttonClassName,
}) => {
	const handleClose = () => {
		openMessage(false);
	};

	return (
		<Dialog
			open={isMessageOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="md"
			fullWidth
		>
			<DialogContent>
				<Grid container spacing={1} justify="center">
					<Grid item xs={10}>
						<Info
							text={message}
							link={link}
							secondText={secondMessage}
							linkText={linkText}
							className={className}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Box display="flex" justifyContent="center" width="100%">
					<Button
						onClick={handleClose}
						color="primary"
						className={buttonClassName}
					>
						OK
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default Message;
