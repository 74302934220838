import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Box,
} from "@material-ui/core/";

import Info from "../components/Info";

interface IProps {
  message: string;
  isMessageOpen: boolean;
  openMessage: (open: boolean) => void;
  buttonClassName: any;
}

const Message: React.FC<IProps> = ({
  message,
  isMessageOpen,
  openMessage,
  buttonClassName,
}) => {
  const handleClose = () => {
    openMessage(false);
  };

  return (
    <Dialog
      open={isMessageOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      scroll="body"
    >
      <DialogContent style={{ overflow: "hidden" }}>
        <Grid container spacing={4} justify="center">
          <Grid item xs={10}>
            <Info text={message} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            onClick={handleClose}
            color="primary"
            className={buttonClassName}
          >
            OK
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default Message;
