import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";

import { responsiveFontSizesTheme as theme } from "./helpers/theme";
import Main from "./@Main";
import Auth from "./@Auth";
import Result from "./@Result";
import GlobalState from "./context/GlobalState";

const App = () => {
	return (
		<>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalState>
					<Router>
						<Switch>
							<Route path="/login/:id" exact component={Auth} />
							<Route path="/merchant/:id" exact component={Main} />
							<Route path="/result/:id" exact component={Result} />
							<Route path="/result/:id/:passport" exact component={Result} />
							{window.location.hostname.indexOf("membersolutions") !== -1 ? (
								<Redirect from="/" to="/login/msi" />
							) : (
								<Redirect from="/" to="/login/efit" />
							)}
						</Switch>
					</Router>
				</GlobalState>
			</ThemeProvider>
		</>
	);
};

export default App;
