import React, {CSSProperties, useState} from "react";
import {
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  Tooltip,
  IconButton,
  Box,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import EyeIcon from "@material-ui/icons/Visibility";
import EyeCrossedIcon from "@material-ui/icons/VisibilityOff";
import { FormikTouched, FormikErrors } from "formik";

import { useStyles } from "./styles";

interface IProps {
  autoComplete?: string;
  autoFocus?: boolean;
  endAdornment?: any;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  helperText?: string;
  label?: string;
  maxLength?: number;
  multiline?: boolean;
  name: string;
  readOnly?: boolean;
  required?: boolean;
  rows?: number;
  rowsMax?: number;
  maskInput?: boolean;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  type?: string;
  value?: string | number;
  disabled?: boolean;
  title?: string;
  max?: number;
  min?: number;
  defaultValue?: string | number;
  onBlur?: (e: any) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyPress?: () => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  pattern?: string;
  onPaste?: (e: any) => void;
}

const InputCustom: React.FC<IProps> = ({
  autoComplete,
  autoFocus,
  endAdornment,
  error,
  helperText,
  label,
  multiline,
  name,
  readOnly,
  required,
  rows,
  rowsMax,
  maskInput,
  touched,
  type = "text",
  value,
  disabled,
  title,
  max,
  min,
  maxLength,
  defaultValue,
  onBlur,
  onChange,
  onKeyPress,
  onFocus,
  pattern,
  onPaste,
}) => {
  const classes = useStyles();

  const [isMasked, setMasked] = useState<boolean>(maskInput || false);

  const toggleMask = function (){
    setMasked(!isMasked);
  }

  const maskButton:CSSProperties = { position: 'absolute', right: '10px', top: '10px' };

  return (
    <>
      {label && (
        <InputLabel
          htmlFor={name}
          style={{ display: "flex", alignItems: "center" }}
        >
          {label}
          {title && (
            <Tooltip
              title={title}
              arrow
              placement="top-start"
              classes={classes}
            >
              <IconButton
                aria-label="delete"
                size="small"
                style={{ width: 15, height: 15, marginLeft: 15 }}
              >
                <HelpIcon fontSize="small" style={{ width: 15, height: 15 }} />
              </IconButton>
            </Tooltip>
          )}
        </InputLabel>
      )}
      <FormControl
        error={Boolean(error)}
        fullWidth
        margin="dense"
        variant="outlined"
      >
        <OutlinedInput
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={readOnly ? "read-only" : ""}
          endAdornment={endAdornment}
          fullWidth
          id={name}
          multiline={multiline}
          name={name}
          rows={rows}
          rowsMax={rowsMax}
          readOnly={readOnly}
          required={required}
          type={isMasked ? 'password':'text'}
          value={value}
          disabled={disabled}
          inputProps={{ min, max, maxLength, pattern }}
          defaultValue={defaultValue}
          onBlur={onBlur}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          onPaste={onPaste}
        />
        {maskInput && (
          <IconButton
          aria-label="delete"
          size="small"
          style={maskButton}
          onClick={toggleMask}>
            {isMasked ? <EyeIcon fontSize="small" style={{ width: 15, height: 15 }} />: <EyeCrossedIcon fontSize="small" style={{ width: 15, height: 15 }} />}
          </IconButton>
        )}

        <FormHelperText>{error ? error : helperText || ""}</FormHelperText>
      </FormControl>
    </>
  );
};

export default InputCustom;
