export const validate = (values: any) => {
  const errors: any = {};

  if (!values.legalBusinessName) {
    errors.legalBusinessName = "This field is required";
  }

  if (!values.doingBusinessName) {
    errors.doingBusinessName = "This field is required";
  }

  if (!values.taxIdNumber) {
    errors.taxIdNumber = "This field is required";
  } else {
    if(countUniqueCharacters(values.taxIdNumber) < 3) {
      errors.taxIdNumber = "Please enter correct value";
    }
  }

  if (!values.businessPhone) {
    errors.businessPhone = "This field is required";
  }

  const rePhone = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/;

  if (!rePhone.test(values.businessPhone)) {
    errors.businessPhone = "This field must be number";
  }

  if (!values.businessEmail) {
    errors.businessEmail = "This field is required";
  }

  const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!reEmail.test(String(values.businessEmail).toLowerCase())) {
    errors.businessEmail = "This field must be email";
  }

  if (!values.businessWebsite) {
    errors.businessWebsite = "This field is required";
  }

  const reUrl = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/;
  if (!reUrl.test(String(values.businessWebsite).toLowerCase())) {
    errors.businessWebsite = "This field must be a valid URL";
  }

  if (!values.countryBusiness) {
    errors.countryBusiness = "This field is required";
  }

  if (!values.addressBusiness) {
    errors.addressBusiness = "This field is required";
  }else {
    let regexp = /.*(P.O. Box)|(POB)|(PO Box).*/g;
    if(regexp.test(values.addressBusiness)) {
      errors.addressBusiness = "Please provide the address where your business is physically located";
    }
  }

  if(values.address2Business){
    let regexp = /.*(P.O. Box)|(POB)|(PO Box).*/g;
    if(regexp.test(values.address2Business)) {
      errors.address2Business = "Please provide the address where your business is physically located";
    }
  }

  if (!values.cityBusiness) {
    errors.cityBusiness = "This field is required";
  }

  if (!values.provinceBusiness) {
    errors.provinceBusiness = "This field is required";
  }

  if (!values.postalCodeBusiness) {
    errors.postalCodeBusiness = "This field is required";
  }else {
      var usRegex = /\d{5}([ \-]\d{4})?/;
      var  canadaRegex = /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/;
      if ((!usRegex.test(values.postalCodeBusiness) && !canadaRegex.test(values.postalCodeBusiness)) || countUniqueCharacters(values.postalCodeBusiness) < 3) {
        errors.postalCodeBusiness = "Please enter correct value";
      }
  }

  if (!values.checked) {
    errors.checked = "This field is required";
  }

  return errors;

  function countUniqueCharacters(s: string) {
    var str = s;
    var uniql = "";
    for (var x = 0; x < str.length; x++) {
      if (uniql.indexOf(str.charAt(x)) == -1) {
        uniql += str[x];
      }
    }
    return uniql.length;
  }
};
