import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    backgroundColor: "#6084FB",
    borderRadius: "20px !important",

    "&:hover": {
      backgroundColor: "#6084FB",
    },
  },

  submitButtonMSI: {
    backgroundColor: "#f38b00",
    borderRadius: "4px",

    "&:hover": {
      backgroundColor: "#f8a433",
    },
  },

  efitLink: {
    color: "#6084FB",

    "&:hover": {
      color: "#6084FB",
    },
  },

  label: {
    fontWeight: 600,
  },

  redError: {
    fontWeight: 400,
    color: "red",
  },
}));
