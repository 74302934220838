import moment from "moment";

export interface IErrors {
	ownerFullName?: string;
	percentageOfOwnership?: string;
	socialSecurity?: string;
	verifySocialSecurity?: string;
	passportNumber?: string;
	dateOfBirth?: string;
	ownerPhone?: string;
	ownerEmail?: string;
	ownerCountry?: string;
	ownerAddress?: string;
	ownerAddress2?: string;
	ownerCity?: string;
	ownerProvince?: string;
	ownerPostalCode?: string;
}

export const validate = (values: any): IErrors => {
	const errors: IErrors = {};

	if (!values.ownerFullName) {
		errors.ownerFullName = "This field is required";
	}

	if (!values.percentageOfOwnership) {
		errors.percentageOfOwnership = "This field is required";
	}

	if (values.percentageOfOwnership && values.percentageOfOwnership > 100) {
		errors.percentageOfOwnership =
			"Should be geater than zero and less than 100";
	}
	var regexp = /^\d{9}$/;
	if (values.ownerCountry === "USA") {
		if (!values.socialSecurity || !values.socialSecurity.length) {
			errors.socialSecurity = "This field is required";
		}
		if (values.socialSecurity.length > 0) {
			if (
				!regexp.test(values.socialSecurity) ||
				countUniqueCharacters(values.socialSecurity) < 3
			) {
				errors.socialSecurity = "Please enter correct value (9 digits)";
			}
			if (values.socialSecurity === "123456789") {
				errors.socialSecurity = "Please enter correct value";
			}
		}
		if (!values.verifySocialSecurity || !values.verifySocialSecurity.length) {
			errors.verifySocialSecurity = "This field is required";
		}
	}

	if (values.ownerCountry === "CAN") {
		if (!values.socialSecurity && !values.passportNumber) {
			errors.socialSecurity = "Either SIN or Passport Number is required";
			errors.passportNumber = "Either SIN or Passport Number is required";
		}
		if (values.socialSecurity && !values.verifySocialSecurity) {
			errors.verifySocialSecurity = "This field is required";
		}
		if (values.passportNumber.length > 0) {
			let regex = /^[a-zA-Z]{2}[0-9]{6}$/;
			if (!regex.test(values.passportNumber)) {
				errors.passportNumber = "Please enter a valid Passport Number";
			}
		}
	}

	if (
		values.verifySocialSecurity &&
		values.socialSecurity &&
		values.verifySocialSecurity !== values.socialSecurity
	) {
		errors.verifySocialSecurity = "SSN/SIN did not match";
	}

	if (!values.dateOfBirth || !values.dateOfBirth.length) {
		errors.dateOfBirth = "This field is required";
	} else {
		let age = moment().diff(values.dateOfBirth, "years");

		if (age < 18) {
			errors.dateOfBirth = "Owner must be at least 18 years old.";
		}

		if (age > 105) {
			errors.dateOfBirth = "Please enter a correct value";
		}
	}

	if (!values.ownerPhone || !values.ownerPhone.length) {
		errors.ownerPhone = "This field is required";
	}

	const rePhone =
		/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/;

	if (!rePhone.test(values.ownerPhone)) {
		errors.ownerPhone = "This field must be number";
	}

	if (!values.ownerEmail || !values.ownerEmail.length) {
		errors.ownerEmail = "This field is required";
	}

	const reEmail =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	if (!reEmail.test(String(values.ownerEmail).toLowerCase())) {
		errors.ownerEmail = "This field must be email";
	}

	if (!values.ownerCountry || !values.ownerCountry.length) {
		errors.ownerCountry = "This field is required";
	}

	if (!values.ownerAddress || !values.ownerAddress.length) {
		errors.ownerAddress = "This field is required";
	} else {
		let regexp = /.*(P.O. Box)|(POB)|(PO Box).*/g;
		if (regexp.test(values.ownerAddress)) {
			errors.ownerAddress =
				"Please provide the physical address of your residence";
		}
	}

	if (values.ownerAddress2) {
		let regexp = /.*(P.O. Box)|(POB)|(PO Box).*/g;
		if (regexp.test(values.ownerAddress2)) {
			errors.ownerAddress2 =
				"Please provide the physical address of your residence";
		}
	}

	if (!values.ownerCity || !values.ownerCity.length) {
		errors.ownerCity = "This field is required";
	}

	if (!values.ownerProvince || !values.ownerProvince.length) {
		errors.ownerProvince = "This field is required";
	}

	if (!values.ownerPostalCode || !values.ownerPostalCode.length) {
		errors.ownerPostalCode = "This field is required";
	} else {
		if (values.ownerCountry && values.ownerCountry.length) {
			var regex = /\d{5}([ \-]\d{4})?/;
			if (values.ownerCountry === "CAN") {
				regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
			}
			if (
				!regex.test(values.ownerPostalCode) ||
				countUniqueCharacters(values.ownerPostalCode) < 3
			) {
				errors.ownerPostalCode = "Please enter correct value";
			}
		}
	}

	return errors;

	function countUniqueCharacters(s: string) {
		var str = s;
		var uniql = "";
		for (var x = 0; x < str.length; x++) {
			if (uniql.indexOf(str.charAt(x)) == -1) {
				uniql += str[x];
			}
		}
		return uniql.length;
	}
};
