import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  efitLink: {
    color: "#6084FB",

    "&:hover": {
      color: "#6084FB",
    },
  },
}));
